<template>
    <b-row class="mt-4 faq">
        <b-col
            cols="12"
            md="12"
        >
            <div
                class="accordion"
                role="tablist"
            >
                <div
                    v-for="faq in page.faqs"
                    :key="`faq-${page.faqs.indexOf(faq)}`"
                    class="item"
                >
                    <div
                        class="question"
                        :class="getClass(page.faqs.indexOf(faq))"
                        @click="toggleAccordion(page.faqs.indexOf(faq))"
                    >
                        <p class="lg-sbold">
                            {{ faq.question }}
                        </p>
                        <minus-icon />
                        <plus-icon />
                    </div>
                    <div
                        :id="getAccordionId(faq)"
                        :class="'accordion ' + getAccordionClass(page.faqs.indexOf(faq))"
                    >
                        <div
                            ref="answer"
                            class="answer"
                        >
                            <p
                                ref="answerContent"
                                class="lg"
                            >
                                {{ faq.answer }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
    import MinusIcon from 'vue-feather-icons/icons/MinusIcon';
    import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
    import { mapState } from 'vuex';
    export default {
        name: 'Faq',
        components: { PlusIcon, MinusIcon },
        data() {
            return {
                index: 0,
                open: true,
            };
        },
        computed: {
            ...mapState('page', ['page']),
        },
        methods: {
            getAccordionId(faq) {
                return 'accordion-' + this.page.faqs.indexOf(faq);
            },
            toggleAccordion(index) {
                let oldIndex = this.index;
                if (oldIndex == index) {
                    this.open = !this.open;
                } else {
                    this.index = index;
                    this.open = true;
                }
                this.setAnswerHeight(oldIndex, index);
            },
            getClass(index) {
                let className = 'collapsed';
                if (this.index == index) {
                    if (this.open) {
                        return 'not-collapsed';
                    } else {
                        return className;
                    }
                }
                return className;
            },
            getAccordionClass(index) {
                return this.index == index && this.open ? 'show' : '';
            },
            setAnswerHeight(oldIndex, index) {
                this.$nextTick(() => {
                    const answerElement = this.$refs.answer[index];
                    const answerContentElement = this.$refs.answerContent[index];

                    let height = 0;
                    if (this.index === index && this.open) {
                        height = answerContentElement.offsetHeight;
                    }
                    answerElement.style.height = `${height}px`;

                    if (typeof oldIndex === 'number' && oldIndex !== index) {
                        const answerOldElement = this.$refs.answer[oldIndex];
                        answerOldElement.style.height = '0px';
                    }
                });
            },
        },
        mounted() {
            this.setAnswerHeight(null, 0);
        },
    };
</script>
<style scoped lang="scss">
.faq {
  .item {
    border: 1px solid $gray-light;
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden;
    .question {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      &.not-collapsed {
        background-color: $primary;
        p {
          color: $white;
        }
        svg {
          stroke: $white;
          &.feather-plus {
            display: none;
          }
        }
      }
      &.collapsed {
        svg {
          &.feather-minus {
            display: none;
          }
        }
      }
      p {
        flex: 1;
      }
    }
    .accordion {
      .answer {
        height: 0;
        transition: 0.5s ease;
        p {
          padding: 16px;
        }
      }
      &.show {
        .answer {
          height: 200px;
          transition: 0.5s ease;
        }
      }
    }
  }
}
</style>
