<template>
    <div class="mt-4 d-flex">
        <div>
            <div
                v-dompurify-html="page.description"
                class="content mb-4"
            />
        </div>
        <div
            class="contact-form mt-mb"
        >
            <form
                id="contact-us"
                @submit.prevent="submit"
            >
                <div
                    class="form-group w100"
                    :class="{ error: errors.email }"
                >
                    <label>{{ $t('form.fields.label.email_address') }}</label>
                    <input
                        v-model="input.email"
                        type="text"
                        class="form-control"
                        name="email"
                        :placeholder="$t('form.fields.placeholder.email_address')"
                    >
                    <span
                        v-if="errors.email"
                        class="error-message"
                    >{{
                        errors.email
                    }}</span>
                </div>
                <div
                    class="form-group w50"
                    :class="{ error: errors.name }"
                >
                    <label>{{ $t('form.fields.label.contact_name') }}</label>
                    <input
                        v-model="input.name"
                        type="text"
                        class="form-control"
                        name="name"
                        :placeholder="$t('form.fields.placeholder.fullName')"
                    >
                    <span
                        v-if="errors.name"
                        class="error-message"
                    >{{
                        errors.name
                    }}</span>
                </div>
                <div
                    class="form-group w50"
                    :class="{ error: errors.phone }"
                >
                    <label>{{ $t('form.fields.label.phone') }}</label>
                    <input
                        v-model="input.phone"
                        type="text"
                        class="form-control"
                        name="phone"
                        :placeholder="$t('form.fields.placeholder.phone')"
                    >
                    <span
                        v-if="errors.phone"
                        class="error-message"
                    >{{
                        errors.phone
                    }}</span>
                </div>

                <div
                    class="form-group w100"
                    :class="{ error: errors.subject }"
                >
                    <label>{{ $t('form.fields.label.subject') }}</label>
                    <multiselect
                        v-model="selectedSubject"
                        :options="subjectOptions"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :placeholder="$t('form.fields.placeholder.selectSubject')"
                        label="text"
                        track-by="value"
                        :select-label="$t('form.fields.text.select')"
                        :deselect-label="$t('form.fields.text.deselect')"
                        :show-no-results="true"
                    >
                        <span slot="noResult">
                            {{ $t('form.fields.text.noResult') }}
                        </span>
                        <span slot="noOptions">{{ $t('form.fields.text.noOptions') }}</span>
                    </multiselect>
                </div>
                <div
                    class="form-group w100"
                    :class="{ error: errors.message }"
                >
                    <label>{{ $t('form.fields.label.message') }} -
                        {{ input.message.length }} / 1000</label>
                    <textarea
                        v-model="input.message"
                        :placeholder="$t('form.fields.placeholder.addMessage')"
                        class="form-control"
                        name="message"
                        rows="6"
                    />
                    <span
                        v-if="errors.message"
                        class="error-message"
                    >{{
                        errors.message
                    }}</span>
                </div>
                <b-button
                    :disabled="sending"
                    variant="primary"
                    class="lg contact"
                    type="submit"
                >
                    {{ $t('form.buttons.sendMessage') }}
                </b-button>
            </form>
        </div>
    </div>
</template>
<script>
    import { i18n } from 'vue-lang-router';
    import Multiselect from 'vue-multiselect';
    import { mapActions, mapGetters, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';
    export default {
        name: 'ContactUs',
        components: {
            Multiselect,
        },
        data() {
            return {
                input: {
                    email: '',
                    phone: '',
                    name: '',
                    message: '',
                    subject: '',
                },
                selectedSubject: null,
                subjectOptions: [],
                errors: {},
                sending: false,
            };
        },
        computed: {
            ...mapState('page', ['inputErrors', 'page']),
            ...mapGetters('auth', ['isLogged']),
            lang() {
                return i18n.locale;
            },
        },
        methods: {
            ...mapActions('page', ['sendMessage']),
            async submit() {
                this.input.subject = this.selectedSubject
                    ? this.selectedSubject.value
                    : '';

                this.errors = formValidations(this.input);
                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;

                await this.sendMessage(this.input);
                this.sending = false;
                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }
                this.success();
            },
            success() {
                this.input = {
                    email: '',
                    phone: '',
                    name: '',
                    message: '',
                    subject: '',
                };
                this.selectedSubject = null;
                this.$toast.open({
                    message: this.$t('notification.messageSent'),
                    type: 'success',
                    duration: 10000,
                });
            },
            initializeUserData() {
                let subjects = this.page.subject;
                subjects = Object.keys(subjects).map(function (key) {
                    return { value: key, text: subjects[key] };
                });
                this.subjectOptions = subjects;
                this.errors = {};
                if (this.isLogged) {
                    this.input.email = this.page.email;
                    this.input.name = this.page.name;
                    this.input.phone = this.page.phone;
                }
                if (this.selectedSubject) {
                    this.initializeSelectedSubject();
                }
            },
            initializeSelectedSubject() {
                let oldSelectedSubject = this.selectedSubject;
                let newSelectedSubjectText = this.page.subject[oldSelectedSubject.value];
                this.selectedSubject = {
                    value: oldSelectedSubject.value,
                    text: newSelectedSubjectText,
                };
            },
        },
        mounted(){
            this.initializeUserData();
        },
    };
</script>
<style lang="scss">
@import 'src/_scss/components/multiselect.scss';
</style>
