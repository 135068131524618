<template>
    <b-row class="mt-4">
        <b-col
            cols="12"
            class="warranty"
        >
            <form
                v-if="!noOrders"
                id="warranty"
                @submit.prevent="submit"
            >
                <div
                    class="form-group"
                    :class="{ error: errors.name, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>{{ $t('form.fields.label.fullName') }}</label>
                    <input
                        v-model="input.name"
                        type="text"
                        class="form-control"
                        name="name"
                        :placeholder="$t('form.fields.placeholder.fullName')"
                    >
                    <span
                        v-if="errors.name"
                        class="error-message"
                    >{{
                        errors.name
                    }}</span>
                </div>
                <div
                    class="form-group"
                    :class="{ error: errors.email, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>{{ $t('form.fields.label.email_address') }}</label>
                    <input
                        v-model="input.email"
                        type="text"
                        class="form-control"
                        name="email"
                        :placeholder="$t('form.fields.placeholder.email_address')"
                    >
                    <span
                        v-if="errors.email"
                        class="error-message"
                    >{{
                        errors.email
                    }}</span>
                </div>
                <div
                    class="form-group"
                    :class="{ error: errors.phone, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>{{ $t('form.fields.label.phone') }}</label>
                    <input
                        v-model="input.phone"
                        type="text"
                        class="form-control"
                        name="phone"
                        :placeholder="$t('form.fields.placeholder.phone')"
                    >
                    <span
                        v-if="errors.phone"
                        class="error-message"
                    >{{
                        errors.phone
                    }}</span>
                </div>
                <div
                    class="form-group"
                    :class="{ error: errors.county, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>{{ $t('form.fields.label.contact_county') }}</label>
                    <multi-select-field
                        :options="allCounties"
                        :placeholder="$t('form.fields.placeholder.selectCounty')"
                        :selected="selectedCounty"
                        type="county"
                        @updateSelectedOption="updateSelectedOption"
                    />
                </div>
                <div
                    class="form-group"
                    :class="{ error: errors.city, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>{{ $t('form.fields.label.contact_city') }}</label>
                    <multi-select-field
                        :options="countyBasedCities"
                        :placeholder="$t('form.fields.placeholder.selectCity')"
                        :selected="selectedCity"
                        type="city"
                        @updateSelectedOption="updateSelectedOption"
                    />
                </div>
                <div
                    class="form-group"
                    :class="{ error: errors.address, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>{{ $t('form.fields.label.contact_address') }}</label>
                    <input
                        v-model="input.address"
                        type="text"
                        class="form-control"
                        name="address"
                        :placeholder="$t('form.fields.placeholder.address')"
                    >
                    <span
                        v-if="errors.address"
                        class="error-message"
                    >{{
                        errors.address
                    }}</span>
                </div>
                <div
                    class="form-group"
                    :class="{ error: errors.description, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>{{ labelDescription }} - {{ input.description.length }} /
                        1000</label>
                    <textarea
                        v-model="input.description"
                        :placeholder="$t('form.fields.placeholder.addMessage')"
                        class="form-control"
                        name="description"
                        rows="4"
                    />
                    <span
                        v-if="errors.description"
                        class="error-message"
                    >{{
                        errors.description
                    }}</span>
                </div>
                <div
                    class="form-group"
                    :class="{ error: errors.products, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>
                        {{
                            type == 'return'
                                ? $t('form.fields.text.productForReturn')
                                : $t('form.fields.text.productForGuarantee')
                        }}</label>
                    <multi-select-field
                        :options="productOptions"
                        :placeholder="$t('form.fields.placeholder.selectProducts')"
                        :selected="selectedProducts"
                        :show-no-results="true"
                        :multiple="true"
                        :close-on-select="false"
                        type="products"
                        @updateSelectedOption="updateSelectedOption"
                    />
                </div>
                <div
                    class="form-group"
                    :class="{ error: errors.iban, w50: isResponsive, w33: !isResponsive }"
                >
                    <label>{{ $t('form.fields.label.iban') }}</label>
                    <input
                        v-model="input.iban"
                        type="text"
                        class="form-control"
                        name="iban"
                        :placeholder="$t('form.fields.placeholder.iban')"
                    >
                    <span
                        v-if="errors.iban"
                        class="error-message"
                    >{{
                        errors.iban
                    }}</span>
                </div>
                <b-button
                    :disabled="sending"
                    variant="primary"
                    class="lg contact"
                    type="submit"
                >
                    {{ $t('form.buttons.sendMessage') }}
                </b-button>
            </form>
            <p
                v-else
                class="sm-sbold"
            >
                {{ $t('page.return.noOrdersText') }}
            </p>
        </b-col>
    </b-row>
</template>
<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import MultiSelectField from '@/components/controls/MultiSelectField';
    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';
    export default {
        name: 'ReturnAndWarrantyForm',
        components: {
            MultiSelectField,
        },
        props: {
            type: {
                type: String,
                default: 'return',
            },
        },
        data() {
            return {
                input: {
                    country: 'ro',
                    email: '',
                    phone: '',
                    name: '',
                    county: '',
                    city: '',
                    type: '',
                    iban: '',
                    address: '',
                    description: '',
                    products: [],
                    order: null,
                },
                allCounties: [],
                countyBasedCities: [],
                productOptions: [],
                selectedCounty: null,
                selectedCity: null,
                selectedProducts: [],
                initializeExistingData: false,
                sending: false,
                errors: {},
                screenWidth: 0,
                firstLoad: true,
            };
        },
        computed: {
            ...mapState('page', ['inputErrors', 'page']),
            ...mapState('general', ['counties', 'cities']),
            ...mapState('user', ['returnData', 'noOrders']),
            labelDescription() {
                return this.type == 'return'
                    ? this.$t('form.fields.text.reasonReturn')
                    : this.$t('form.fields.text.reasonGuarantee');
            },
            lang() {
                return i18n.locale;
            },
            isResponsive(){
                return this.screenWidth < 768;
            }
        },
        methods: {
            ...mapActions('page', ['requestReturn']),
            ...mapActions('general', ['getCounties', 'getCities']),
            ...mapActions('user', ['getDataForReturnForm']),
            async submit() {
                this.input.county = this.selectedCounty ? this.selectedCounty.text : '';
                this.input.city = this.selectedCity ? this.selectedCity.text : '';
                let result = this.extractOrderAndProducts();
                this.input.products = result.products;
                this.input.order = result.order;
                this.input.type = this.type;
                this.errors = formValidations(this.input);

                if (Object.keys(this.errors).length !== 0) {
                    return;
                }

                this.sending = true;
                await this.requestReturn(this.input);
                this.sending = false;
                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }
                this.success();
            },
            success() {
                this.input = {
                    email: '',
                    phone: '',
                    name: '',
                    country: 'ro',
                    county: '',
                    city: '',
                    address: '',
                    iban: '',
                    description: '',
                };
                this.selectedCounty = null;
                this.selectedCity = null;
                this.selectedProducts = [];
                this.productOptions = [];
                this.originalProductsOptions = [];
                this.$toast.open({
                    message: this.$t('notification.returnRequestSent'),
                    type: 'success',
                    duration: 10000,
                });
                this.getFormData();
            },
            extractOrderAndProducts(){
                let order = null;
                let products = [];

                this.selectedProducts.forEach(item => {
                    let parts = item.value.split('|');
                    let [orderIdPart, productIdPart] = parts[1].split('-');

                    if (!order) {
                        order = orderIdPart;
                    }

                    products.push(productIdPart);
                });

                return {
                    order,
                    products
                };
            },
            setCounties() {
                let storageCounties = JSON.parse(localStorage.getItem('counties'));
                storageCounties = Object.keys(storageCounties).map(function (key) {
                    return { value: key, text: storageCounties[key] };
                });
                this.allCounties = storageCounties;
            },
            setCountyCities() {
                if(!this.firstLoad){
                    this.selectedCity = null;
                }
                if (this.selectedCounty) {
                    let storageCounties = JSON.parse(localStorage.getItem('counties'));
                    if (this.initializeExistingData) {
                        this.initializeExistingData = false;
                    } else {
                        this.selectedCity = null;
                    }
                    if (storageCounties) {
                        let countyCode = Object.keys(storageCounties).find(
                            (key) => storageCounties[key] === this.selectedCounty.text
                        );
                        if (countyCode) {
                            let allCities = JSON.parse(localStorage.getItem('cities'))[
                                countyCode
                            ];
                            allCities = Object.keys(allCities).map(function (key) {
                                return { value: key, text: allCities[key] };
                            });
                            this.countyBasedCities = allCities;
                        }
                    }
                } else {
                    this.countyBasedCities = [];
                    this.selectedCity = null;
                }
            },
            async getFormData() {
                this.initializeExistingData = true;
                await this.getDataForReturnForm();
                this.productOptions = [];
                if (!this.noOrders) {
                    let data = this.returnData.items_info;
                    data.forEach((item, index) => {
                        this.productOptions.push({
                            value: index + '|' + item.order_id + '-' + item.product_id,
                            text:
                                this.$t('form.fields.text.orderNr') +
                                item.order_id +
                                ', ' +
                                item.product_name,
                        });
                    });
                    this.originalProductsOptions = this.productOptions;
                    let shippingInfo = this.returnData.shipping_info;
                    this.input.email = this.input.email === '' ? shippingInfo.email : this.input.email;
                    this.input.phone = this.input.phone === '' ? shippingInfo.phone : this.input.phone;
                    this.input.name = this.input.name === '' ? shippingInfo.name : this.input.name;
                    this.input.county = this.input.county === '' ? shippingInfo.county : this.input.county;
                    this.input.city = this.input.city === '' ? shippingInfo.city : this.input.city;
                    this.input.address = this.input.address === '' ? shippingInfo.address : this.input.address;
                    if(!this.selectedCounty || !this.selectedCity){
                        this.selectedCounty = {
                            text: shippingInfo.county,
                            value: shippingInfo.county,
                        };
                        this.selectedCity = {
                            text: shippingInfo.city,
                            value: shippingInfo.city,
                        };
                    }
                }

                if(this.selectedProducts.length > 0){
                    this.initializeSelectedProducts();
                }
                if(this.firstLoad){
                    setTimeout(() => {
                        this.firstLoad = false;
                    }, 100);
                }
            },
            initialize() {
                let counties = localStorage.getItem('counties');
                let cities = localStorage.getItem('cities');
                if (!counties) {
                    this.getCounties();
                } else {
                    this.setCounties();
                }
                if (!cities) {
                    this.getCities();
                }
                this.getFormData();
            },
            initializeSelectedProducts(){
                const newSelectedProducts = [];
                this.selectedProducts.forEach(selectedProduct => {
                    const foundProduct = this.productOptions.find(product => product.value === selectedProduct.value);
                    if (foundProduct) {
                        newSelectedProducts.push(foundProduct);
                    }
                });
                this.selectedProducts = newSelectedProducts;
            },
            updateSelectedOption(data) {
                if (data.type == 'city') {
                    this.selectedCity = data.value;
                } else if (data.type == 'county') {
                    this.selectedCounty = data.value;
                } else {
                    this.getProductsByOrder(data.value);
                    this.selectedProducts = data.value;
                }
            },
            getProductsByOrder(option){
                if(option.length === 0){
                    this.productOptions = this.originalProductsOptions;
                }
                else if(option.length == 1){
                    let orderNumber = option[0].value.split('|')[1].split('-')[0];
                    this.productOptions = this.productOptions.filter(option => {
                        const optionOrderNumber = option.value.split('|')[1].split('-')[0];
                        return optionOrderNumber === orderNumber;
                    });
                }

            },
            screenSizeChanged() {
                this.screenWidth = window.innerWidth;
            },
        },
        mounted() {
            window.addEventListener('resize', this.screenSizeChanged);
            this.screenWidth = window.innerWidth;
            this.initialize();
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
        watch: {
            counties: {
                handler() {
                    this.setCounties();
                },
                deep: true,
            },
            selectedCounty: {
                handler() {
                    this.setCountyCities();
                },
                deep: true,
            },
            lang: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
            page: {
                handler() {
                    this.errors = {};
                },
                deep: true,
            },
        },
    };
</script>
<style lang="scss">
@import 'src/_scss/components/multiselect.scss';
</style>
