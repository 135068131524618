<template>
    <div class="container page">
        <loading-spinner
            v-if="loading && !existData"
            class="fixed"
        />
        <b-row
            v-if="existData"
            class="mtd-40"
        >
            <b-col cols="12">
                <h4>{{ page.title }}</h4>
                <div
                    v-if="!isContact"
                    v-dompurify-html="page.description"
                    class="content mt-4"
                />
            </b-col>
        </b-row>
        <contact-us
            v-if="isContact && existData"
            class="contact-us"
        />
        <faq v-if="isFaq && existData" />
        <b-row v-if="(isReturn || isWarranty) && !isLogged && existData">
            <b-col cols="12">
                <p class="sm-sbold mt-3">
                    {{ $t('page.return.authRequiredText') }}
                </p>
                <b-button
                    variant="primary"
                    class="sm mt-1"
                    @click="showLoginPopup()"
                >
                    {{ $t('header.navbar.login') }}
                </b-button>
            </b-col>
        </b-row>
        <b-row
            v-if="isCookie && existData"
            class="mt-4"
        >
            <b-col cols="12">
                <p class="lg-sbold mb-4">
                    {{ $t('page.cookie.heading') }}
                </p>
                <div class="d-flex">
                    <label>
                        <div class="custom-checkbox">
                            <input
                                type="checkbox"
                                name="cookie-optional"
                                :checked="cookieIsAccepted"
                                @change="updateCookieConsent($event)"
                            >
                            <div class="checkmark">
                                <check-icon />
                            </div>
                        </div>
                        <div class="text">
                            {{ $t('page.cookie.optionalCheckboxLabel') }}
                        </div>
                    </label>
                    <label class="disabled">
                        <div class="custom-checkbox">
                            <input
                                type="checkbox"
                                name="cookie-mandatory"
                                :disabled="true"
                                :checked="true"
                            >
                            <div class="checkmark">
                                <check-icon />
                            </div>
                        </div>
                        <div class="text">
                            {{ $t('page.cookie.mandatoryCheckboxLabel') }}
                        </div>
                    </label>
                </div>
            </b-col>
        </b-row>

        <return-and-warranty-form
            v-if="isReturn && isLogged && existData"
            type="return"
        />
        <return-and-warranty-form
            v-if="isWarranty && isLogged && existData"
            type="warranty"
        />
    </div>
</template>
<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import ContactUs from '../components/static-pages/ContactUs';
    import Faq from '../components/static-pages/Faq';
    import ReturnAndWarrantyForm from '../components/static-pages/ReturnAndWarrantyForm';
    export default {
        components: {
            ContactUs,
            Faq,
            ReturnAndWarrantyForm,
            LoadingSpinner,
            CheckIcon,
        },
        name: 'Page',
        data() {
            return {
                contactSlugs: ['contact', 'kapcsolat'],
                faqSlugs: ['faq', 'gyakran-ismetelt-kerdesek'],
                returnSlugs: [
                    'formular-returnare-produs',
                    'termek-visszakuldesi-urlap',
                ],
                warrantySlugs: ['garantii-si-service', 'garancia-szerviz'],
                cookieSlugs: ['cookie-uri', 'sutik'],
                slug: '',
            };
        },
        computed: {
            ...mapState('page', ['page', 'loading']),
            ...mapState('general', ['cookieIsAccepted']),
            ...mapGetters('auth', ['isLogged']),
            isContact() {
                return this.contactSlugs.includes(this.slug);
            },
            isFaq() {
                return this.faqSlugs.includes(this.slug) && this.page.faqs;
            },
            isReturn() {
                return this.returnSlugs.includes(this.slug);
            },
            isWarranty() {
                return this.warrantySlugs.includes(this.slug);
            },
            isCookie() {
                return this.cookieSlugs.includes(this.slug);
            },
            existData(){
                if(this.loading && this.page && this.page.slug == this.slug){
                    return true;
                }
                else if(!this.loading && this.page){
                    return true;
                }
                return false;
            }
        },
        methods: {
            ...mapActions('page', ['getPage']),
            ...mapMutations('general', [
                'setModal',
                'setCookieBanner',
            ]),
            showLoginPopup() {
                this.setModal({
                    show: true,
                    type: 'login',
                });
            },
            updateCookieConsent($event) {
                if ($event.target.checked) {
                    localStorage.setItem('cookieIsAccepted', true);
                    this.setCookieBanner(false);
                    this.$emit('updateCookie', true);
                } else {
                    localStorage.removeItem('cookieIsAccepted');
                    this.setCookieBanner(false);
                    this.$emit('updateCookie', false);
                }
            },
        },
        created() {
            this.slug = this.$route.params.slug;
            this.getPage(this.slug);
        },
        watch: {
            '$route.params'() {
                this.slug = this.$route.params.slug;
                this.getPage(this.slug);
            },
        },
        metaInfo() {
            return {
                title: this.page.meta_title,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.page.meta_description,
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.page.meta_keywords,
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.page.meta_title,
                        template: (chunk) => `${chunk} | Pescario.ro`,
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: window.location.origin + this.$route.fullPath,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.page.meta_description,
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: this.page.image,
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.origin + this.$route.fullPath,
                    },
                ],
            };
        },
    };
</script>
